var PIXELSIGNS = PIXELSIGNS || {};

(function ($) {
    // USE STRICT
    "use strict";

    PIXELSIGNS.initialize = {
        init: function () {
            PIXELSIGNS.initialize.general();
        },

        general: function () {
            var wow = new WOW({
                boxClass: "wow",
                animateClass: "animated",
                offset: 0,
                mobile: false,
                live: true,
                scrollContainer: null,
            });
            wow.init();

            // Show first content by default
            $("#pix-tabs-nav li:nth-child(2)").addClass("active");
            $("#pix-tabs-content .content").hide();
            $("#pix-tabs-content .content:nth-child(2)").show();

            // click function
            $("#pix-tabs-nav li").on("click", function () {
                $("#pix-tabs-nav li").removeClass("active");
                $(this).addClass("active");
                $("#pix-tabs-content .content").hide();

                var activeTab = $(this).find("a").attr("href");
                $(activeTab).fadeIn(400);
                return false;
            });
        },
    };

    PIXELSIGNS.documentOnReady = {
        init: function () {
            PIXELSIGNS.initialize.init();
        },
    };

    PIXELSIGNS.documentOnLoad = {
        init: function () {
            $(".page-loader").fadeOut("slow");
        },
    };

    $(".pricing-tab-switcher, .tab-btn").on("click", function () {
        $(".pricing-tab-switcher, .tab-btn").toggleClass("active");
        $(".pricing-tab").toggleClass("seleceted");

        $(".pricing-amount").toggleClass("change-subs-duration");
        $(".pricing-action").toggleClass("change-subs-duration");
    });

    // Initialize Functions
    $(document).ready(PIXELSIGNS.documentOnReady.init);
    $(window).on("load", PIXELSIGNS.documentOnLoad.init);

    $("#contact-form").submit(function (e) {
        e.preventDefault();

        const $submitButton = $(this).find("button");
        const $responseContainer = $(this).find(".response");

        $.ajax({
            beforeSend: function () {
                $submitButton.prop("disabled", true);
                $submitButton.text("Sending..");
                $responseContainer.addClass("d-none");
                $responseContainer.find(".alert").removeClass("alert-danger");
                $responseContainer.find(".alert").removeClass("alert-success");
            },
            url: "/contact-us",
            type: "POST",
            dataType: "json",
            data: {
                name: $("#contact-name").val(),
                email: $("#contact-email").val(),
                userMessage: $("#contact-message").val(),
            },
            headers: {
                "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
            },
            success: function (response) {
                if (response.message) {
                    $responseContainer.removeClass("d-none");
                    $responseContainer
                        .find(".alert")
                        .addClass("alert-success")
                        .text(response.message);
                }

                $submitButton.prop("disabled", false);
                $submitButton.text("Send Your Massage");
            },
            error: function (jqXHR, textStatus, errorThrown) {
                const errors = jqXHR.responseJSON.errors;
                let errorList = "<ul class='m-0'>";

                for (let field in errors) {
                    errorList += `<li>${errors[field]}</li>`;
                }

                errorList += "</ul>";

                $responseContainer.removeClass("d-none");
                $responseContainer
                    .find(".alert")
                    .addClass("alert-danger")
                    .html(errorList);

                $submitButton.prop("disabled", false);
                $submitButton.text("Send Your Massage");
            },
        });
    });

    $(".pricing-tab-switcher, .tab-btn")
        .unbind("click")
        .click(function () {
            $(".pricing-tab-switcher, .tab-btn").toggleClass("active");
            $(".pricing-tab").toggleClass("seleceted");

            $(".pricing-amount").toggleClass("change-subs-duration");
            $(".pricing-action").toggleClass("change-subs-duration");
        });

    $("#home-video").on("click", function () {
        $(this).hide();

        var script = document.createElement("script");
        script.src = "https://player.vimeo.com/api/player.js";
        script.onload = function () {
            var options = {
                id: 540205046,
                width: 640,
                loop: true,
                autoplay: true,
                muted: true,
                byline: false,
                portrait: false,
                title: false,
            };

            var playerElement = document.getElementById("vimeo-player");
            playerElement.style.display = "";

            var player = new Vimeo.Player("vimeo-player", options);

            player.ready().then(function () {
                player.play();
            });
        };
        document.head.appendChild(script);
    });

    $("#intercom-button").on("click", function () {
        var intercomScript1 = document.createElement("script");
        intercomScript1.innerHTML = `
            window.intercomSettings = {
                app_id: "lnzn99ec"
            };`;

        var intercomScript2 = document.createElement("script");
        intercomScript2.type = "text/javascript";
        intercomScript2.async = true;
        intercomScript2.src = "https://widget.intercom.io/widget/lnzn99ec";
        intercomScript2.onload = function () {
            if (typeof Intercom === "function") {
                Intercom("reattach_activator");
                Intercom("update", window.intercomSettings);
                Intercom("show");
            }
        };
        
        document.head.appendChild(intercomScript1);
        document.head.appendChild(intercomScript2);
    });
})(jQuery);
